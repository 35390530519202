import styled from "styled-components";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

export const NavbarStyled = styled(Navbar)`
  background-color: #feeb9c;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px 8px;
`;

export const NavbarBrandStyled = styled(Navbar.Brand)`
  display: flex;
  height: auto;
`;

export const NavItemStyled = styled(Nav.Item)`
  display: flex;
  align-items: center;
  align-items: bottom;
  padding: 8px;
  margin-right: 15px;
  font-weight: bold;
`;

export const NavDropdownStyled = styled(NavDropdown)`
  font-weight: bold;
  margin-right: 15px;

  a {
    color: rgba(0, 0, 0, 0.5);
  }

  .dropdown-menu {
    background-color: #feeb9c;
  }
`;

export const NavDropdownItemStyled = styled(Nav.Item)`
  padding: 4px 24px;
  font-weight: bold;
  white-space: nowrap;

  :hover {
    background-color: #ffd700;

    a {
      color: rgba(0, 0, 0, 0.8);
    }
  }
`;

export const Image = styled.img`
  height: 35px;
  margin-right: 10px;
`;

export const LinkStyled = styled(Link)`
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
`;

// Adicionando uma linha para garantir que o arquivo seja tratado como um módulo
export {};
