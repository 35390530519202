import styled from "styled-components";

// Container principal
export const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
`;

// Formulário principal
export const FormContainer = styled.form`
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
`;

// Agrupamento de campos do formulário
export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 16px;
`;

// Campo de entrada personalizado
export const InputGroupWrapper = styled.input`
  padding: 12px;
  border-right: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
`;

// Wrapper para ícone de campo
export const ImageFieldWrapper = styled.div`
  border-left: 0px;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Ícone de imagem
export const InputImage = styled.img`
  width: 20px;
`;

// Botão estilizado
export const ButtonStyled = styled.button`
  display: inline-block;
  padding: 12px 0;
  background-color: ${({ theme }) => theme?.button?.primary || "#007bff"};
  color: ${({ theme }) => theme?.button?.text || "#fff"};
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 4px;
  border: none;
  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme?.button?.hover || "#0056b3"};
  }
`;

// Texto de destaque em vermelho
export const TextDanger = styled.span`
  color: red;
`;
