import React, { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import {
  Image,
  NavbarStyled,
  NavbarBrandStyled,
  LinkStyled,
  NavItemStyled,
  NavDropdownStyled,
  NavDropdownItemStyled,
} from "./styled";
import logo from "../../images/logo/beehed-horizontal.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LicenseModal from "./LicenseModal";

const NavbarPage: React.FC = () => {  // Ensure correct typing for the functional component
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showLicenseModal, setShowLicenseModal] = useState(false);

  const makeLogout = async () => {
    logout();
    navigate("/login");
  };

  const openLicenseModal = async () => {
    setShowProfileMenu(false);
    setShowLicenseModal(true);
  };

  if (!user) {
    return null;  // Return null or a loading screen if the user is not logged in
  }

  return (
    <NavbarStyled expand="md">
      <NavbarBrandStyled>
        <Image src={logo} alt="Logo" />
      </NavbarBrandStyled>
      <Navbar.Toggle aria-controls="navbarNav" />
      <Navbar.Collapse id="navbarNav">
        <Nav>
          <NavItemStyled>
            <LinkStyled to="/dashboard">Dashboard</LinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <LinkStyled to="/financeiro">Financeiro</LinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <LinkStyled to="/estoque">Estoque</LinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <LinkStyled to="/servicos">Serviços</LinkStyled>
          </NavItemStyled>
          <NavDropdownStyled title="Agendamentos" id="agendamentosDropdown">
            <NavDropdownItemStyled>
              <LinkStyled to="/planejamento">Planejamento</LinkStyled>
            </NavDropdownItemStyled>
            <NavDropdownItemStyled>
              <LinkStyled to="/agendamento">Agendar</LinkStyled>
            </NavDropdownItemStyled>
            <NavDropdownItemStyled>
              <LinkStyled to="/gestor-agendamento">
                Gestor de Agendamentos
              </LinkStyled>
            </NavDropdownItemStyled>
          </NavDropdownStyled>
          <NavItemStyled>
            <LinkStyled to="/clientes">Clientes</LinkStyled>
          </NavItemStyled>
          <NavItemStyled>
            <LinkStyled to="/Vendas">Vendas</LinkStyled>
          </NavItemStyled>
        </Nav>
        <NavDropdownStyled
          className="ms-md-auto profile"
          align="end"
          title={`Bem vindo, ${user.user_name}`}
          show={showProfileMenu}
          onToggle={(show: boolean) => {
            setShowProfileMenu(show);
          }}
        >
          <NavDropdownItemStyled>
            <LinkStyled to="/changePassword">Trocar senha</LinkStyled>
          </NavDropdownItemStyled>
          <NavDropdownItemStyled onClick={openLicenseModal}>
            <LinkStyled to="#">Gerenciar licença</LinkStyled>
          </NavDropdownItemStyled>
          <NavDropdownItemStyled>
            <LinkStyled to="/settings">Configurações</LinkStyled>
          </NavDropdownItemStyled>
          <NavDropdown.Divider />
          <NavDropdownItemStyled onClick={makeLogout}>
            <LinkStyled to="#">Logout</LinkStyled>
          </NavDropdownItemStyled>
        </NavDropdownStyled>
        <LicenseModal show={showLicenseModal} handleModalHide={() => { setShowLicenseModal(false); }} />
      </Navbar.Collapse>
    </NavbarStyled>
  );
};

export default NavbarPage;
