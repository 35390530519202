
class AuthService {
    private static token: string | null = null;
    public static updateToken(authToken: string | null){
      AuthService.token = authToken;
    }

    public static getToken(): string | null{
      return AuthService.token;
    }
  
    //region Logout pelo axios
    private static logoutFn: (() => void) | null = null;
    
    public static registerLogoutFn(fn: () => void) {
      AuthService.logoutFn = fn;
    }
    
    public static logout() {      
      if (AuthService.logoutFn) AuthService.logoutFn();
      else console.warn('AuthService: Função de logout não foi registrado.');
    }
    //endregion
  }
  
  export default AuthService;
